import React from 'react';

import { Typography } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { UniversityInfo } from '@ha/api/v2/types/UniversityInfo';
import { PartnerInfo } from '@ha/api/v2/types/UserInformation';
import { units } from '@hbf/dsl/core';

import { useIntl } from 'ha/i18n';

import { ImgixSize } from '../Image/constants';
import { Imgix } from '../Image/Imgix';
import { LanguageSwitcher } from '../LanguageSwitcher/components/LanguageSwitcher';

import { BaseNavigationFooter } from './BaseNavigationFooter';
import {
  NavigationFooterLinks,
  NO_FOLLOW_REL,
  SocialLinks,
} from './BaseNavigationFooterLinks';
import { HousingAnywhereLogo } from './components/NavigationLogo/NavigationLogo';
import { PARTNERSHIP_TYPE_TRANSLATIONS } from './constants';

const useStyles = makeStyles()(theme => ({
  logo: {
    fill: theme.palette.secondary.main,
  },
  logoSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  partnerLogo: {
    borderBottom: units.border(1, 'solid', theme.palette.mono.main),
    paddingBottom: theme.utils.spacing('ref/spacing/3'),
    marginBottom: theme.utils.spacing('ref/spacing/3'),
  },
  housingAnywhereLogo: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.utils.spacing('ref/spacing/2'),
  },
  image: {
    maxHeight: '100px',
    maxWidth: '220px',
    width: 'auto',
    height: 'auto',
    border: units.border(1, 'solid', theme.palette.mono.main),
    padding: theme.utils.spacing('ref/spacing/3'),
    borderRadius: theme.utils.borderRadius('ref/radius/sm'),
    backgroundColor: theme.palette.neutral[100],
  },
}));

const FooterCoBrandedHousingAnywhereLinks = () => {
  const { T, urlResolver } = useIntl();

  return (
    <NavigationFooterLinks title={T('nav.footer.ha')}>
      <NavigationFooterLinks.Link name="About" href={urlResolver.getAboutUrl()}>
        {T('nav.footer.about')}
      </NavigationFooterLinks.Link>
      <NavigationFooterLinks.Link
        name="Terms and conditions"
        href={urlResolver.getTermsUrl()}
        rel={NO_FOLLOW_REL}
      >
        {T('nav.footer.terms')}
      </NavigationFooterLinks.Link>
      <NavigationFooterLinks.Link
        name="Privacy Policy"
        href={urlResolver.getPrivacyPolicyUrl()}
        rel={NO_FOLLOW_REL}
      >
        {T('nav.footer.privacy')}
      </NavigationFooterLinks.Link>
      <NavigationFooterLinks.Link
        name="Cookie Policy"
        href={urlResolver.getCookiePolicyUrl()}
        rel={NO_FOLLOW_REL}
      >
        {T('nav.footer.cookie')}
      </NavigationFooterLinks.Link>
    </NavigationFooterLinks>
  );
};

const FooterCoBrandedTenantLinks = () => {
  const { T, urlResolver } = useIntl();

  return (
    <NavigationFooterLinks title={T('nav.footer.tenants')}>
      <NavigationFooterLinks.Link
        name="How to rent"
        href={urlResolver.getRenting()}
      >
        {T('nav.footer.how_it_works')}
      </NavigationFooterLinks.Link>
      <NavigationFooterLinks.Link
        name="Payments tenants"
        href={urlResolver.getPaymentsTenant()}
      >
        {T('nav.footer.pay_rent')}
      </NavigationFooterLinks.Link>
    </NavigationFooterLinks>
  );
};

interface Props {
  partnerInfo: PartnerInfo | UniversityInfo;
}

export const CoBrandedNavigationFooter = ({
  partnerInfo: { partnershipType, iconUrl, name },
}: Props) => {
  const { T } = useIntl();
  const { classes } = useStyles();

  return (
    <BaseNavigationFooter
      slots={{
        startSection: (
          <React.Fragment>
            <div className={classes.logoSection}>
              <div className={classes.partnerLogo}>
                <Imgix
                  src={iconUrl}
                  htmlAttributes={{
                    alt: name,
                    role: 'presentation',
                    'data-test-locator':
                      'CoBrandedNavigationFooter/PartnerLogo',
                  }}
                  className={classes.image}
                  width={ImgixSize.small.w}
                />
              </div>
              <div className={classes.housingAnywhereLogo}>
                <Typography variant="caption/regular">
                  {T(PARTNERSHIP_TYPE_TRANSLATIONS[partnershipType])}
                </Typography>
                <HousingAnywhereLogo
                  size="full"
                  color="secondary"
                  className={classes.logo}
                />
              </div>
            </div>

            <LanguageSwitcher size="full-secondary" />
          </React.Fragment>
        ),
        middleSection: (
          <React.Fragment>
            <BaseNavigationFooter.LinkSectionColumn>
              <FooterCoBrandedHousingAnywhereLinks />
            </BaseNavigationFooter.LinkSectionColumn>
            <BaseNavigationFooter.LinkSectionColumn>
              <FooterCoBrandedTenantLinks />
            </BaseNavigationFooter.LinkSectionColumn>
          </React.Fragment>
        ),
        endSection: <SocialLinks />,
      }}
    />
  );
};
