/* eslint-disable react/jsx-no-constructed-context-values */
import React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { useMediaQuery, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

import { makeStyles } from 'tss-react/mui';

import { BrandingType } from '@ha/api/v2/types/UserInformation';
import { isHaMobileApp } from '@ha/core/MobileApp';
import { Divider, IconButton, Typography } from '@hbf/dsl/core';
import { ArrowLeft } from '@hbf/icons/brand-bold';

import { useIntl } from 'ha/i18n';
import {
  getIsAdvertiser,
  getIsAuthenticated,
} from 'ha/modules/AuthLogic/selectors';

import { SmartTrack as Track } from 'ha/components/track/SmartTrack';
import { ImgixSize } from 'ha/modules/Image/constants';
import { Imgix } from 'ha/modules/Image/Imgix';

import { usePage } from '../Page/contexts';

import { AdminImpersonationBanner } from './components/AdminImpersonationBanner/AdminImpersonationBanner';
import { LoadableAdvertiserQuickActions } from './components/AdvertiserQuickActions/AdvertiserQuickActions.lazy';
import { HeaderQuickActions } from './components/HeaderQuickActions/HeaderQuickActions';
import { LoggedOutQuickActions } from './components/LoggedOutQuickActions/LoggedOutQuickActions';
import { NavigationAppBar } from './components/NavigationAppBar/NavigationAppBar';
import {
  NavigationLogo,
  NavigationLogoProps,
} from './components/NavigationLogo/NavigationLogo';
import { LoadableTenantQuickActions } from './components/TenantQuickActions/TenantQuickActions.lazy';
import { PARTNERSHIP_TYPE_TRANSLATIONS } from './constants';

const useStyles = makeStyles()(theme => ({
  universityPartnerContainer: {
    display: 'flex',
    gap: theme.utils.spacing('ref/spacing/3'),
  },
  universityLogo: {
    display: 'flex',
    alignItems: 'center',
  },
  partnerInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  partnerIconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  partnerIcon: {
    maxHeight: '60px',
    maxWidth: '130px',
    width: 'auto',
    height: 'auto',
  },
}));

export const DefaultLogo = ({ size }: Pick<NavigationLogoProps, 'size'>) => {
  const { urlResolver } = useIntl();

  const isAdvertiser = useSelector(getIsAdvertiser);

  return (
    <Track name="Clicked Logo in header" type="icon">
      <a
        href={urlResolver.getHomeUrl({ explicitNoRedirect: isAdvertiser })}
        data-test-locator="Navigation/LogoHomePageLink"
      >
        <NavigationLogo size={size} />
      </a>
    </Track>
  );
};

const DefaultQuickActions = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const isAdvertiser = useSelector(getIsAdvertiser);

  if (isAuthenticated) {
    if (isAdvertiser) return <LoadableAdvertiserQuickActions />;
    return <LoadableTenantQuickActions />;
  }

  return <LoggedOutQuickActions />;
};

interface NavigationHeaderProps {
  sticky?: boolean;
  slots?: {
    appBar?: typeof NavigationAppBar;

    logo?: React.ReactNode;
    dynamicIsland?: React.ReactNode;
    quickActions?: React.ReactNode;

    above?: React.ReactNode;
    below?: React.ReactNode;
  };
}

export const NavigationHeader = ({
  sticky = false,
  slots = {},
}: NavigationHeaderProps) => {
  const { appBar: AppBar = NavigationAppBar } = slots;
  const { classes } = useStyles();
  const theme = useTheme();
  const isLargerThanMD = useMediaQuery(theme.breakpoints.up('md'));
  const { partnerInfo } = usePage();

  const { T, urlResolver } = useIntl();

  const navigate = useNavigate();
  const location = useLocation();

  const logoSlot = (() => {
    const isHomePage = location.pathname === '/';
    const isInboxPage = location.pathname.includes('/my/inbox');

    const goBackHandler = () => {
      if (isInboxPage) {
        return navigate(urlResolver.getHomeUrl({ explicitNoRedirect: true }));
      }

      return navigate(-1);
    };

    if (partnerInfo && partnerInfo.brandingType === BrandingType.CoBranded) {
      const partnershipType =
        PARTNERSHIP_TYPE_TRANSLATIONS[partnerInfo.partnershipType];

      return (
        <div className={classes.universityPartnerContainer}>
          <div className={classes.universityLogo}>
            <a
              href={urlResolver.getHomeUrl({
                explicitNoRedirect: true,
              })}
              target="_blank"
              className={classes.partnerIconContainer}
              aria-label={T('Home')}
              title="University logo"
              rel="noreferrer"
            >
              <Imgix
                src={partnerInfo.iconUrl}
                className={classes.partnerIcon}
                htmlAttributes={{
                  alt: partnerInfo.name,
                }}
                width={ImgixSize.xxxsmall.w}
              />
            </a>
          </div>

          {isLargerThanMD && (
            <React.Fragment>
              <Divider orientation="vertical" />
              <div className={classes.partnerInfo}>
                <Typography variant="caption/regular">
                  {T(partnershipType)}
                </Typography>
                <Typography variant="body/sm-semibold">
                  {T('navbar.advertiser_logo.subtitle')}
                </Typography>
              </div>
            </React.Fragment>
          )}
        </div>
      );
    }

    const shouldRenderBackButton = Boolean(isHaMobileApp() && !isHomePage);

    return shouldRenderBackButton ? (
      <IconButton onClick={goBackHandler}>
        <ArrowLeft />
      </IconButton>
    ) : (
      slots.logo ?? <DefaultLogo />
    );
  })();

  return (
    <React.Fragment>
      <AdminImpersonationBanner />
      <AppBar
        sticky={sticky}
        slots={{
          above: slots.above,
          below: slots.below,
          logo: logoSlot,
          dynamicIsland: slots.dynamicIsland,
          quickActions: slots.quickActions ?? (
            <HeaderQuickActions.Context.Provider
              value={{ hasSidebar: false, showCTA: true }}
            >
              <DefaultQuickActions />
            </HeaderQuickActions.Context.Provider>
          ),
        }}
      />
    </React.Fragment>
  );
};

NavigationHeader.DefaultLogo = DefaultLogo;
NavigationHeader.DefaultQuickActions = DefaultQuickActions;
