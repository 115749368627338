export interface ImpersonationInfo {
  id: number;
  name: string;
}

export enum BrandingType {
  UniversitySignupPage = 'university_signup_page',
  CoBranded = 'co_branded',
}

export enum PartnershipType {
  PartneredWith = 'partnered_with',
  PoweredBy = 'powered_by',
}

export interface PartnerInfo {
  iconUrl: string;
  partnerUrl: string;
  brandingType: BrandingType;
  partnershipType: PartnershipType;
  name: string;
}

export interface UserInformation {
  hasAvatar: boolean;
  hasPhone: boolean;
  hasFacebookProfile: boolean;
  isProfessional: boolean;
  hasProfileChangesInProgress?: boolean;
  hasPayoutChangesInProgress?: boolean;
  savedPaymentMethodsCount?: number;
  savedPayoutMethodsCount?: number;
  listingsCount: number;
  tenantConversationsCount?: number;
  advertiserConversationsCount?: number;
  tenantBookingsCount?: number;
  advertiserBookingsCount: number;
  unreadInboxCount?: number;
  impersonation?: ImpersonationInfo;
  partner?: PartnerInfo;
  subscriptionFeatures?: string[];
  isKYCDone?: boolean;
  freeBookingsUsage?: number;
  nextRenewalDate?: string;
}
